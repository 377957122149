import {
  COLLABORATOR_ACTIONS_BY_STATE,
  PERMISSION_BY_PAGE,
  PRODUCT_ACTIONS_BY_STATE,
  REQUEST_ACTIONS_BY_STATE,
} from '@/shared/utils/constants/permission.constants';
import { Injectable } from '@angular/core';
import { RouteDataService } from './routeData.service';
import { ROUTE_METADATA_KEY } from '@/shared/utils/constants/route-metada-key.constants';
import {
  getMenuIdByModuleName,
  hasUserPermission,
  imSuperAdmin,
} from '@/shared/utils/functions/permission.function';
import { ModuleType, PermissionKey } from '../interfaces/permission.interface';

type ModuleActions = {
  list?: PermissionKey;
  detail?: PermissionKey;
  create?: PermissionKey;
  update?: PermissionKey;
  delete?: PermissionKey;
  date?: PermissionKey;
  reasign?: PermissionKey;
  cancel?: PermissionKey;
  active?: PermissionKey;
  deactivate?: PermissionKey;
};

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private routeDataService: RouteDataService) {}

  /**
   * Obtiene la validación de permisos de un módulo de acuerdo a un dato de la ruta actual.
   *
   * @param permissionKey - Llave del permiso.
   */
  getPermissionValidationByKey(permissionKey: PermissionKey): boolean {
    const { menuId, moduleName } = this.getMenuModuleData();

    if (!moduleName) return false;

    const permissionKeyTyped =
      permissionKey as keyof (typeof PERMISSION_BY_PAGE)[typeof moduleName];

    const permission = PERMISSION_BY_PAGE[moduleName][permissionKeyTyped];

    return imSuperAdmin() || hasUserPermission(menuId, permission);
  }

  /**
   * Obtiene la validación de permisos de un módulo directamente por el codigo del permiso.
   *
   * @param permissionCode - Llave del permiso.
   */
  getPermissionValidationByCodes(
    menuId: string,
    permissionCode: string
  ): boolean {
    return imSuperAdmin() || hasUserPermission(menuId, permissionCode);
  }

  /**
   * Obtiene la validacion de un estado de una entidad y la de permisos.
   *
   * @param entityName - Nombre de la entidad.
   * @param entityState - Estado de la entidad.
   * @param entityAction - Acción de la entidad.
   * @param permissionKey - Llave del permiso.
   */
  getStateAndPermissionValidation({
    entityName,
    entityState,
    entityAction,
    permissionKey,
  }: {
    entityName: 'request' | 'product' | 'collaborator';
    entityState: string | undefined;
    entityAction: string;
    permissionKey: PermissionKey;
  }): boolean {
    let actionsByThisState: string[] = [];

    if (!entityState) return false;

    if (entityName === 'request') {
      actionsByThisState = REQUEST_ACTIONS_BY_STATE[entityState] || [];
    }

    if (entityName === 'product') {
      actionsByThisState = PRODUCT_ACTIONS_BY_STATE[entityState] || [];
    }

    if (entityName === 'collaborator') {
      actionsByThisState = COLLABORATOR_ACTIONS_BY_STATE[entityState] || [];
    }

    const { menuId, moduleName } = this.getMenuModuleData();

    if (!moduleName) return false;

    const permissionKeyTyped =
      permissionKey as keyof (typeof PERMISSION_BY_PAGE)[typeof moduleName];

    const permission = PERMISSION_BY_PAGE[moduleName][permissionKeyTyped];

    return (
      actionsByThisState.includes(entityAction) &&
      hasUserPermission(menuId, permission)
    );
  }

  /**
   * Obtiene la validación de permisos de un módulo.
   *
   * @param modulePermissionByActions - Permisions de las acciones del módulo que se quieren validar.
   *
   * @description
   * Generalmente se usa en paginas de listado donde se necesitan validar varios permisos
   * para los botones de acción.
   */
  getModulePermsValidation(modulePermissionByActions: ModuleActions) {
    const {
      list,
      detail,
      create,
      update,
      delete: deletePermission,
      date,
      reasign,
      cancel,
      active,
      deactivate,
    } = modulePermissionByActions;

    return {
      LIST: list ? this.getPermissionValidationByKey(list) : false,
      DETAIL: detail ? this.getPermissionValidationByKey(detail) : false,
      CREATE: create ? this.getPermissionValidationByKey(create) : false,
      UPDATE: update ? this.getPermissionValidationByKey(update) : false,
      DELETE: deletePermission
        ? this.getPermissionValidationByKey(deletePermission)
        : false,
      DATE: date ? this.getPermissionValidationByKey(date) : false,
      REASIGN: reasign ? this.getPermissionValidationByKey(reasign) : false,
      CANCEL: cancel ? this.getPermissionValidationByKey(cancel) : false,
      ACTIVE: active ? this.getPermissionValidationByKey(active) : false,
      DEACTIVATE: deactivate
        ? this.getPermissionValidationByKey(deactivate)
        : false,
    };
  }

  /**
   * Obtiene la data del módulo del menú.
   *
   * @description
   * Obtiene el nombre del modulo en la metadata de una ruta
   * y obtiene el id del menu por el nombre del modulo.
   */
  getMenuModuleData() {
    const moduleName = this.routeDataService.getRouteMetadataFromParent(
      ROUTE_METADATA_KEY.MODULE_NAME
    );

    const menuId = this.getMenuModuleId(moduleName!);

    return {
      moduleName,
      menuId,
    };
  }

  /**
   * Obtiene el id del menu por el nombre clave del modulo.
   *
   * @param moduleName - Nombre clave del modulo.
   */
  private getMenuModuleId(moduleName: ModuleType) {
    return getMenuIdByModuleName(moduleName);
  }
}
