import { RouteMetadataKey } from '@/shared/utils/constants/route-metada-key.constants';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleType } from '../interfaces/permission.interface';

@Injectable({
  providedIn: 'root',
})
export class RouteDataService {
  private moduleName = signal<ModuleType | null>(null);

  constructor(private router: Router) {}

  /**
   * Encuentra el nombre del modulo buscando la llave en la metadata
   * de alguna ruta en el arbol de rutas de la ruta actual.
   *
   * @param key - Llave de la metadata.
   */
  getRouteMetadataFromParent(key: RouteMetadataKey) {
    let route = this.router.routerState.root;

    let moduleName: ModuleType | null = null;

    while (route.firstChild) {
      route = route.firstChild;

      const routeData = route.snapshot.data[key];

      if (route.snapshot.data && routeData) {
        moduleName = routeData;
      }
    }

    return moduleName;
  }

  /**
   * Setea el nombre del modulo.
   *
   * @param permissionKey - Llave del modulo.
   */
  setModuleName(permissionKey: ModuleType) {
    this.moduleName.set(permissionKey);
  }

  /**
   * Obtiene el valor de la variable seteado en este servicio.
   * En este caso, el nombre del modulo.
   */
  getModuleName() {
    return this.moduleName();
  }
}
